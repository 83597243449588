<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="500"
    >

      <v-card :disabled="btnLoading"
              :loading="btnLoading">

        <v-card-title>
          {{ titleDialog }}
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">

          <v-text-field outlined
                        label="Nom du quota cib"
                        dense
                        :error-messages="errors.name"
                        v-model="form.name"></v-text-field>

          <v-text-field outlined
                        label="Montant maximum"
                        dense
                        hide-details="auto"
                        :error-messages="errors.amount"
                        v-model="form.amount"></v-text-field>

          <v-radio-group v-model="form.user_verification_status"
                         hide-details="auto"
                         :error-messages="errors.user_verification_status"
                         label="Statut vérification de l'utilisateur">

            <v-radio value="verified" label="Vérifié"></v-radio>
            <v-radio value="unverified" label="Non vérifié"></v-radio>
          </v-radio-group>


        </v-card-text>


        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="btnLoading"
                 color="primary"
                 depressed
                 type="submit"
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      titleDialog: "",
      dialog: false,
      btnLoading: false,
      form: {},
      errors: {},
    }
  },
  methods: {
    open(quota) {
      this.titleDialog = quota ? "Modifier un quota CIB" : "Ajouter un quota CIB"
      this.dialog = true
      this.errors = {}
      this.form = {
        id: quota ? quota.id : null,
        name: quota ? quota.name : null,
        amount: quota ? quota.amount : null,
        user_verification_status: quota ? quota.user_verification_status : "verified",
      }
    },
    close() {
      this.dialog = false
    },
    save() {
      this.errors = {}
      let url = this.form.id ? '/v1/quotas-cib/update' : "/v1/quotas-cib/store"
      let data = this.form
      this.btnLoading = true
      HTTP.post(url, data).then(() => {
        this.btnLoading = false
        this.$successMessage = "Cette quota CIB a été enregistrée avec succès."
        this.close()
        this.$emit('refresh')
      }).catch(err => {
        this.btnLoading = false
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur s'est produite veuillez corriger."
        console.log(err)
      })
    },
  }
}
</script>

